/* App.css */
.App {
  text-align: center;
  font-family: 'Arial', sans-serif;
  background: linear-gradient(135deg, #f0f4f8, #dce3e9);
  min-height: 100vh;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.title {
  font-size: 3rem;
  color: #1e88e5;
  margin-bottom: 2px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.logos {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
}

.logo {
  height: 100px;
  margin: 0 20px;
  transition: transform 0.3s ease-in-out;
}

.logo:hover {
  transform: scale(1.1);
}

.bingo-card {
  display: inline-block;
  margin: 10px;
  padding: 12px;
  border-radius: 15px;
  background-color: white;
  border: 6px solid #1e88e5;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease-in-out, border 0.3s ease-in-out;
}

.bingo-card:hover {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  border: 6px solid #1565c0;
}

.bingo-row {
  display: flex;
}

.bingo-number {
  width: 105px; 
  height: 105px; 
  margin: 5px;
  font-size: 14px; /* Adjusted base font size */
  padding: 10px; /* Padding for better readability */
  background: linear-gradient(135deg, #e3f2fd, #90caf9);
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #1e88e5;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
    display: flex;
  justify-content: center;
  align-items: center;
  white-space: normal; /* Allow wrapping */
  word-wrap: break-word; /* Allow word break */
  overflow: hidden;
  line-height: 1.2; /* Tighter line-height to fit more lines */
}

.bingo-number.long-word {
  font-size: 0.85rem; /* Dynamically reduce font size for long words */
}

.bingo-number:hover {
  transform: translateY(-5px);
  background: linear-gradient(135deg, #90caf9, #42a5f5);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.bingo-number.marked {
  background: linear-gradient(135deg, #66bb6a, #43a047);
  color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  animation: bounce 0.5s ease;
}

@keyframes bounce {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}


.bingo-number.free-space {
  background-image: url("sec.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  font-size: 0; /* Hiding text now that we are using an image in this cel  */
  color: rgb(224, 25, 25); /* Adjust text color to stand out against the background */
}


button:disabled {
  cursor: not-allowed;
  background-color: #bdbdbd;
  color: white;
  transform: none;
}

.bingo-popup {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1e88e5;
  color: white;
  padding: 60px 100px;
  font-size: 6rem;
  font-weight: bold;
  border-radius: 15px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  animation: flash-background 0.5s infinite alternate;
  z-index: 10;
}

@keyframes flash-background {
  0% {
    background-color: white;
    color: #1e88e5;
  }
  100% {
    background-color: #1e88e5;
    color: white;
  }
}

/* Confetti */
.confetti {
  position: absolute;
  width: 22px;
  height: 50px;
  background-color: #ff4081;
  z-index: 5;
  animation: confetti-fall 2s infinite ease-out;
}

.confetti:nth-child(2) {
  background-color: #ffeb3b;
  animation-delay: 0.2s;
}

.confetti:nth-child(3) {
  background-color: #7c4dff;
  animation-delay: 0.4s;
}

.confetti:nth-child(4) {
  background-color: #4caf50;
  animation-delay: 0.6s;
}

.confetti:nth-child(5) {
  background-color: #03a9f4;
  animation-delay: 0.8s;
}

@keyframes confetti-fall {
  0% {
    transform: translateY(-100vh) rotate(0deg);
  }
  100% {
    transform: translateY(100vh) rotate(720deg);
  }
}

/* Controls for the buttons */
.controls {
  margin-top: 20px;
  display: flex;
  gap: 15px;
  justify-content: center;
}

button {
  padding: 10px 20px;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.reset-button {
  background-color: #cf3434;
  color: white;
}

.new-card-button {
  background-color: #1e88e5;
  color: white;
}

button:hover {
  transform: translateY(-3px);
  background-color: #1565c0;
}

button:active {
  transform: translateY(0);
  background-color: #1565c0;
}
